import { Api } from '@/libraries/api'

async function getTerm(termType) {
  const url = `/service/${termType}`
  const result = await Api().get(url)
  const { item } = result.data.data
  return item
}

export async function getTermsOfService() {
  return getTerm('terms_of_service')
}

export async function getTermsOfPrivacy() {
  return getTerm('terms_of_privacy')
}

export async function getTermsOfEvent() {
  return getTerm('terms_of_event')
}

export async function getTermsOfMarketing() {
  return getTerm('terms_of_marketing')
}

// export default {
//   getTermsOfService,
//   getTermsOfPrivacy,
//   getTermsOfEvent,
//   getTermsOfMarketing
// }
