/* eslint-disable vue/no-v-html */
<template>
  <div class="terms-wrap">
    <v-container class="pt-0 px-0 pt-md-16 px-md-4">
      <v-row
        justify="center"
      >
        <v-col
          cols="12"
          sm="12"
          md="10"
          lg="8"
          class="pa-0"
        >
          <v-container>
            <v-row no-gutters>
              <v-col
                cols="6"
              >
                <!-- color="#007AFF" -->
                <v-btn
                  block
                  tile
                  color="#FFF"
                  class="elevation-0 btn-select-term"
                  :class="{'btn-activate' : isActiveTos}"
                  @click="goPage('/terms/terms_of_service')"
                >
                  이용약관
                </v-btn>
              </v-col>
              <v-col
                cols="6"
              >
                <v-btn
                  block
                  tile
                  color="#FFF"
                  class="elevation-0 btn-select-term"
                  :class="{'btn-activate' : isActiveTop}"
                  @click="goPage('/terms/terms_of_privacy')"
                >
                  개인정보취급방침
                </v-btn>
              </v-col>
            </v-row>
            <v-row
              class="my-md-12"
              no-gutters
            >
              <v-col
                cols="12"
                class="pa-md-12"
                style="background: #FFF; border-radius: 5px;"
              >
                <mavon-editor
                  class="md"
                  language="en"
                  :transition="false"
                  :value="item.content"
                  :subfield="false"
                  :default-open="'preview'"
                  :toolbars-flag="false"
                  :editable="false"
                  :scroll-style="true"
                  :ishljs="true"
                  preview-background="#FFF"
                  :box-shadow="false"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { getTermsOfService, getTermsOfPrivacy } from './api/terms'
// import marked from '../../libraries/editor/marked'
import 'mavon-editor/dist/css/index.css'

export default {
  data() {
    return {
      isActiveTos: false,
      isActiveTop: false,
      item: {}
    }
  },
  created() {
    this.init()
  },
  methods: {
    async init() {
      this.isActiveTos = false
      this.isActiveTop = false

      if (this.$route.path === '/terms/terms_of_service') {
        this.isActiveTos = true
        this.item = await getTermsOfService()
      } else if (this.$route.path === '/terms/terms_of_privacy') {
        this.isActiveTop = true
        this.item = await getTermsOfPrivacy()
      }
    },
    goPage(path) {
      this.$router.push(path).catch(() => {})
    }
  }
}
</script>

<style lang="scss" scoped>
.terms-wrap {
  background-color: #F9F9F9;
}
.btn-select-term {
  height: 60px !important;
}
.btn-activate {
  background-color: #025BBC !important;
  color: #FFF;
}
.v-note-wrapper {
  border: none !important;
  z-index: 4 !important;
}
</style>
